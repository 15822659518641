import styled from 'styled-components';
import { breakpoint } from 'theme';

import DotsGrid from 'components/molecules/dots-grid';
import Container from 'components/atoms/container';
import Typography from 'components/atoms/typography';
import WpImage from 'components/atoms/wp-image';

import { TextCenterMod } from './image-head-title-description';

export const StyledWrapper = styled.div`
  background: ${({ theme }) => theme.colors.complementary1};
  position: relative;
`;

export const StyledContainerBreadcrumb = styled(Container)`
  padding-top: ${({ theme }) => theme.gap.md};
  max-width: ${({ theme }) => theme.wrapper};
`;

export const StyledContainerTitle = styled(Container)<TextCenterMod>`
  max-width: 918px;
  padding-block: ${({ theme }) => theme.gap.md};
  text-align: ${({ position }) => (position === 'center' ? 'center' : 'left')};

  ${breakpoint.md} {
    padding-block: ${({ theme }) => theme.gap.xl};
  }
`;

export const StyledDotsGridTwo = styled(DotsGrid)`
  display: none;
  z-index: 1;
  top: 46%;

  ${breakpoint.lg} {
    display: block;
    left: 10px;
    top: 79%;
  }

  ${breakpoint.xl} {
    left: 15px;
  }
`;

export const StyledDotsOrange = styled(DotsGrid)`
  z-index: 3;
  top: 46%;
  right: 26px;

  ${breakpoint.lg} {
    display: block;
    right: 14px;
    top: 49%;
  }
`;

export const StyledTitle = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.gap.sm};

  ${breakpoint.lg} {
    margin-bottom: ${({ theme }) => theme.gap.md};
  }
`;

export const StyledWpImage = styled(WpImage)`
  z-index: 2;
  height: 100%;
  margin-bottom: -${({ theme }) => theme.gap.md};

  ${breakpoint.lg} {
    margin-bottom: -${({ theme }) => theme.gap.xl};
    min-width: 1368px;
  }
`;

export const StyledWrapperBanner = styled.div`
  position: relative;

  &:before {
    width: 100%;
    height: 25%;
    max-height: 160px;
    left: 0;
    position: absolute;
    background: ${({ theme }) => theme.colors.complementary1};
    content: '';
  }

  ${breakpoint.md} {
    &:before {
      height: 29%;
      max-height: 320px;
    }
  }
`;

export const StyledContainerBanner = styled.div`
  padding-inline: ${({ theme }) => theme.gap.sm};
  padding-block: 0px;
  height: 216px;

  ${breakpoint.md} {
    height: 570px;
    max-width: 1440px;
    margin: auto;
    padding-inline: 37px;
  }
`;

export const StyledDescription = styled(Typography)`
  margin-block: ${({ theme }) => theme.gap.md};

  ${breakpoint.md} {
    max-width: 920px;
    text-align: center;
    margin: auto;
    margin-block: ${({ theme }) => theme.gap.xl};
  }
`;
