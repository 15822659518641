import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { theme } from 'theme';
import { PageContextType } from 'types';
import { IndustriesType } from 'types/home-page.d';

import Layout from 'components/templates/layout';
import ChoseTeamSection, { ChoseTeamSectionTypes } from 'components/organisms/chose-team-section';
import { BannerPrimaryType } from 'components/organisms/head-title-description-image';
import ImageHeadTitleDescription from 'components/organisms/image-head-title-description';
import IndustriesSlider from 'components/organisms/industries-slider';
import OurDepartments, { OurDepartmentsType } from 'components/organisms/our-departments';
import OurMissionSection, {
  TextBannerWithImageType,
} from 'components/organisms/our-mission-section';
import RecruitmentProcessSteps, {
  RecruitmentProcessStepsType,
} from 'components/organisms/recruitment-process-steps';
import TextBannerCardSection, {
  TextBannerCardType,
} from 'components/organisms/text-banner-card-section';
import BannerCard from 'components/molecules/banner-card';
import CompanyTextContainer from 'components/molecules/company-text-container';
import LetsTalkBanner, { LetsTalkBannerType } from 'components/molecules/lets-talk-banner';
import Breadcrumb from 'components/atoms/breadcrumb';

type DataProps = {
  commonComponents: {
    commonComponentsData: {
      letsTalkBanner: LetsTalkBannerType;
      otherServicesBlock: TextBannerCardType;
    };
  };
  offersRecruitmentPage: {
    template: {
      recruitmentData: {
        bannerQuaternary: BannerPrimaryType;
        recruitmentProcess: RecruitmentProcessStepsType;
        recruitmentTeam: TextBannerWithImageType;
        advantagesSection: TextBannerCardType;
        recruitmentOurDepartments: OurDepartmentsType;
        recruitmentIndustries: IndustriesType;
        chooseTeam: ChoseTeamSectionTypes;
      };
    };
  };
};
const OffersRecruitmentPage = ({
  pageContext,
  data: {
    offersRecruitmentPage: {
      template: {
        recruitmentData: {
          bannerQuaternary,
          recruitmentProcess,
          recruitmentTeam,
          advantagesSection,
          recruitmentOurDepartments,
          recruitmentIndustries,
          chooseTeam,
        },
      },
    },
    commonComponents: {
      commonComponentsData: { letsTalkBanner, otherServicesBlock },
    },
  },
}: PageProps<DataProps, PageContextType>) => (
  <Layout {...{ pageContext }}>
    <ImageHeadTitleDescription
      showDots
      titleTop={bannerQuaternary.heading}
      subtitleTop={bannerQuaternary.text}
      positionTitle="left"
      image={bannerQuaternary.image}
    >
      <Breadcrumb {...{ pageContext }} />
    </ImageHeadTitleDescription>
    <CompanyTextContainer>
      <RecruitmentProcessSteps {...recruitmentProcess} mobilePadding={`${theme.gap.mdl} 0`} />
    </CompanyTextContainer>
    <OurMissionSection margin="72px auto 0" firstSection={recruitmentTeam} />
    <TextBannerCardSection
      padding={`0 0 ${theme.gap.xl}`}
      {...advantagesSection}
      bgColor={theme.colors.white}
      variantHeading="title9"
      mobilePadding={`${theme.gap.mdl} ${theme.gap.sm} 36px`}
    >
      {advantagesSection.cardList.map((item) => (
        <BannerCard
          {...item}
          bgColor="white"
          margin="0"
          mobileMargin="0"
          padding={`${theme.gap.md} 0`}
          key={item.text}
        />
      ))}
    </TextBannerCardSection>
    <ChoseTeamSection {...chooseTeam} />
    <OurDepartments isSmallPadding {...recruitmentOurDepartments} />
    <IndustriesSlider
      isWhite
      specialColorBg
      bgDark
      {...recruitmentIndustries}
      cards={recruitmentIndustries.industriesList}
    />
    <LetsTalkBanner reverseHalfBg {...letsTalkBanner} />
    <TextBannerCardSection
      {...otherServicesBlock}
      variantHeading="title7"
      textColumn
      mobilePadding={`${theme.gap.mdl} ${theme.gap.sm}`}
    >
      {otherServicesBlock && otherServicesBlock.cardList
        ? otherServicesBlock.cardList.map((item) => <BannerCard {...item} key={item.text} />)
        : null}
    </TextBannerCardSection>
  </Layout>
);
export const query = graphql`
  query ($id: String!, $commonComponentsSlug: String!) {
    offersRecruitmentPage: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_OffersRecruitment {
          recruitmentData {
            bannerQuaternary {
              heading
              text
              image {
                ...WpImageFragment
              }
            }
            recruitmentProcess {
              heading
              subTitle
              text
              processSteps {
                heading
                text
              }
            }
            recruitmentTeam {
              heading
              text: description
              image {
                ...WpImageFragment
              }
              bulletsList {
                text
              }
              linkButton {
                icon {
                  name
                }
                label
                link {
                  ariaLabel
                  internal: wpInternal {
                    ... on WpPage {
                      uri
                    }
                  }
                  external {
                    target
                    url
                  }
                }
              }
            }
            advantagesSection {
              heading
              subTitle
              text
              cardList: advantagesList {
                text
                heading
                icon {
                  name
                }
              }
            }
            chooseTeam {
              choseTeamHeading: heading
              choseTeamImage: image {
                ...WpImageFragment
              }
              choseTeamText: text
              teamList {
                teamListHeading: heading
                teamListDescription: description
                teamListIcon: icon {
                  name
                }
                teamListLinkButton: linkButton {
                  label
                  link {
                    ariaLabel
                    internal: wpInternal {
                      ... on WpPage {
                        uri
                      }
                    }
                    external {
                      target
                      url
                    }
                  }
                  icon {
                    name
                  }
                }
              }
            }
            recruitmentOurDepartments {
              description
              heading
              departmentsList {
                name
                positionx
                positiony
                link {
                  internal: wpInternal {
                    ... on WpPage {
                      uri
                    }
                  }
                }
              }
            }
            recruitmentIndustries {
              heading
              subHeading
              linkButton {
                icon {
                  name
                }
                label
                link {
                  ariaLabel
                  internal: wpInternal {
                    ... on WpPage {
                      uri
                    }
                  }
                  external {
                    target
                    url
                  }
                }
              }
              industriesList {
                heading
                icon {
                  name
                }
                linkButton {
                  label
                  icon {
                    name
                  }
                  link {
                    ariaLabel
                    internal: wpInternal {
                      ... on WpPage {
                        uri
                      }
                    }
                    external {
                      target
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    commonComponents: wpPage(slug: { eq: $commonComponentsSlug }) {
      commonComponentsData {
        letsTalkBanner {
          heading
          description
          linkButton {
            label
            icon {
              name
            }
            link {
              ariaLabel
              internal: wpInternal {
                ... on WpPage {
                  uri
                }
              }
            }
          }
        }
        otherServicesBlock {
          subTitle
          heading
          text
          cardList: servicesList {
            heading
            text
            icon {
              name
            }
            linkButton {
              label
              link {
                ariaLabel
                internal: wpInternal {
                  ... on WpPage {
                    id
                    uri
                  }
                }
                external {
                  target
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default OffersRecruitmentPage;
