import React, { ReactElement } from 'react';

import Typography from 'components/atoms/typography';
import { WpImageType } from 'components/atoms/wp-image';

import { useScreenService } from 'hooks/use-screen-service';

import {
  StyledContainerBanner,
  StyledContainerBreadcrumb,
  StyledContainerTitle,
  StyledDotsGridTwo,
  StyledDotsOrange,
  StyledTitle,
  StyledWpImage,
  StyledWrapper,
  StyledWrapperBanner,
} from './image-head-title-description.styles';

export type ImageHeadTitleDescriptionType = {
  children?: ReactElement;
  showDots: boolean;
  positionTitle: PositionTitleType;
  image: WpImageType;
} & Record<'titleTop' | 'subtitleTop', string>;

export type TextCenterMod = {
  position?: PositionTitleType;
};

export type PositionTitleType = 'center' | 'left';

const ImageHeadTitleDescription = ({
  titleTop,
  subtitleTop,
  children,
  showDots,
  positionTitle = 'left',
  image,
}: ImageHeadTitleDescriptionType) => {
  const { isMobile } = useScreenService();
  return (
    <>
      <StyledWrapper>
        <StyledContainerBreadcrumb>{children}</StyledContainerBreadcrumb>
        <StyledContainerTitle position={positionTitle}>
          <StyledTitle color="white" variant="title1" component="h1" html={titleTop} />
          {subtitleTop ? (
            <Typography color="whiteAlpha72" variant="title2" component="h2" html={subtitleTop} />
          ) : null}
        </StyledContainerTitle>
        {showDots ? (
          <StyledDotsGridTwo
            width={isMobile ? 0 : 3}
            height={isMobile ? 0 : 7}
            color={isMobile ? 'complementary2' : 'complementary2'}
          />
        ) : null}
      </StyledWrapper>
      <StyledWrapperBanner>
        <StyledContainerBanner>
          <StyledWpImage {...image} />
          {showDots ? (
            <StyledDotsOrange
              width={isMobile ? 7 : 6}
              height={isMobile ? 6 : 7}
              color={isMobile ? 'complementary3' : 'complementary3'}
            />
          ) : null}
        </StyledContainerBanner>
      </StyledWrapperBanner>
    </>
  );
};

export default ImageHeadTitleDescription;
