import React, { useState } from 'react';
import { navigate } from 'gatsby';

import { ButtonType } from 'components/atoms/button';
import { IconType } from 'components/atoms/icon';
import Link from 'components/atoms/link';
import { WpImageType } from 'components/atoms/wp-image';

import { useScreenService } from 'hooks/use-screen-service';

import {
  StyledCardButton,
  StyledCardDescription,
  StyledCardIcon,
  StyledCardTeamButton,
  StyledCardText,
  StyledImageTextContainer,
  StyledSelectionCard,
  StyledSelectionCards,
  StyledSelectionCardsLoaded,
  StyledSelectionMobile,
  StyledSubTitle,
  StyledTitle,
  StyledWpImage,
  StyledWrapper,
  StyledWrapperFullWith,
} from './chose-team-section.styles';

export type ChoseTeamSectionTypes = {
  choseTeamHeading: string;
  choseTeamImage: WpImageType;
  choseTeamText: string;
  teamList: ChoseTeamSectionType[];
};

export type ChoseTeamSectionType = {
  teamListHeading: string;
  teamListDescription: string;
  teamListIcon: IconType;
  teamListLinkButton: ButtonType;
};

const ChoseTeamSection = ({
  choseTeamHeading,
  choseTeamImage,
  choseTeamText,
  teamList,
}: ChoseTeamSectionTypes) => {
  const { isMobile } = useScreenService();
  const [isShownTeam, setIsShownTeam] = useState(false);
  const handleClick = () => {
    setIsShownTeam((current) => !current);
  };
  let currentLanguage = 'PL';
  if (typeof window !== 'undefined') {
    if (window.location.pathname.includes('/en/')) {
      currentLanguage = 'EN';
    }
  }

  function getUri(linkButton: ButtonType): string {
    if (typeof linkButton.link?.internal === 'object' && linkButton.link?.internal !== null) {
      return linkButton.link.internal.uri;
    }
    return '';
  }
  const textShowMore = currentLanguage === 'PL' ? 'Pokaż więcej' : 'Show more';

  return (
    <>
      <StyledWpImage {...choseTeamImage} />
      <StyledWrapper>
        <StyledImageTextContainer>
          <StyledTitle html={choseTeamHeading} variant="title2" color="gray100" />
          <StyledSubTitle html={choseTeamText} variant="body1" color="gray100" />
        </StyledImageTextContainer>
        {isMobile ? (
          <StyledWrapperFullWith>
            {teamList ? (
              <>
                <StyledSelectionCards>
                  {teamList.slice(0, 4).map((list, index) => (
                    <StyledSelectionMobile key={index}>
                      <StyledCardIcon name={list.teamListIcon.name} />
                      <StyledCardText variant="title3" component="h3" html={list.teamListHeading} />
                      <StyledCardButton
                        {...list.teamListLinkButton}
                        icon={{ name: isMobile ? 'arrow-right-big' : 'arrow-right' }}
                        width="max-content"
                        variant="reversed-underline"
                        className="card-with-icon__button"
                      />
                    </StyledSelectionMobile>
                  ))}
                </StyledSelectionCards>
                {isShownTeam ? null : (
                  <StyledCardTeamButton onClick={handleClick}>{textShowMore}</StyledCardTeamButton>
                )}
                {isShownTeam ? (
                  <div>
                    <StyledSelectionCardsLoaded>
                      {teamList.slice(4, 20).map((list, index) => (
                        <StyledSelectionMobile key={index}>
                          <StyledCardIcon name={list.teamListIcon.name} />
                          <StyledCardText
                            variant="title3"
                            component="h3"
                            html={list.teamListHeading}
                          />
                          <StyledCardButton
                            {...list.teamListLinkButton}
                            icon={{ name: isMobile ? 'arrow-right-big' : 'arrow-right' }}
                            width="max-content"
                            variant="reversed-underline"
                            className="card-with-icon__button"
                          />
                        </StyledSelectionMobile>
                      ))}
                    </StyledSelectionCardsLoaded>
                  </div>
                ) : null}{' '}
              </>
            ) : null}
          </StyledWrapperFullWith>
        ) : (
          <div>
            {teamList ? (
              <div>
                <StyledSelectionCards>
                  {teamList.map((list, index) => (
                    <StyledSelectionCard
                      key={list.teamListHeading}
                      index={index}
                      total={teamList.length}
                      data-link={getUri(list.teamListLinkButton)}
                      onClick={() => navigate(getUri(list.teamListLinkButton) || '')}
                    >
                      <StyledCardIcon name={list.teamListIcon.name} />
                      {getUri(list.teamListLinkButton) ? (
                        <Link
                          internal={{ uri: getUri(list.teamListLinkButton) }}
                          ariaLabel={list.teamListHeading}
                        >
                          <StyledCardText
                            variant="title3"
                            component="h3"
                            html={list.teamListHeading}
                          />
                        </Link>
                      ) : (
                        <StyledCardText
                          variant="title3"
                          component="h3"
                          html={list.teamListHeading}
                        />
                      )}

                      {list.teamListDescription ? (
                        <StyledCardDescription
                          color="gray100"
                          variant="title5"
                          html={list.teamListDescription}
                        />
                      ) : null}

                      <StyledCardButton
                        {...list.teamListLinkButton}
                        icon={{ name: isMobile ? 'arrow-right-big' : 'arrow-right' }}
                        width="max-content"
                        variant="reversed-underline"
                        className="card-with-icon__button"
                      />
                    </StyledSelectionCard>
                  ))}
                </StyledSelectionCards>
              </div>
            ) : null}
          </div>
        )}
      </StyledWrapper>
    </>
  );
};
export default ChoseTeamSection;
