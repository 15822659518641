import styled, { css } from 'styled-components';
import { breakpoint } from 'theme';

import Button from 'components/atoms/button';
import Container from 'components/atoms/container';
import Icon from 'components/atoms/icon';
import Typography from 'components/atoms/typography';
import WpImage from 'components/atoms/wp-image';

type StyledSelectionCardProps = {
  index: number;
  total: number;
};

export const StyledWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  max-width: ${({ theme }) => theme.wrapper};
  width: 100%;
  margin-inline: auto;
  padding-inline: ${({ theme }) => theme.gap.sm};
  padding-block: ${({ theme }) => `${theme.gap.sm} ${theme.gap.xxs} `};

  ${breakpoint.md} {
    padding-block: ${({ theme }) => `${theme.gap.xl} 24px`};
  }

  ${breakpoint.lg} {
    padding-inline: 0;
  }

  &[data-variant='secondary'] {
    .listing-title {
      padding-block: ${({ theme }) => `${theme.gap.xs} 0`};

      ${breakpoint.md} {
        padding-block: ${({ theme }) => `${theme.gap.sm} 0`};
      }
    }
  }
`;

export const StyledWpImage = styled(WpImage)`
  display: flex;
  margin-inline: auto;
  height: 100%;
  height: 216px;
  width: 100%;
  min-width: 360px;
  max-width: ${({ theme }) => theme.wrapperXxl};

  ${breakpoint.md} {
    min-height: 484px;
    width: 100%;
    max-width: ${({ theme }) => theme.wrapperXxl};
  }
`;

export const StyledImageTextContainer = styled(Container)`
  margin-top: 0;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 2;
  width: ${({ theme }) => theme.wrapper};
  padding-inline: 0;

  ${breakpoint.md} {
    margin-top: -195px;
    padding: 64px 64px 32px 64px;
  }
`;

export const StyledSubTitle = styled(Typography)``;

export const StyledTitle = styled(Typography)`
  ${breakpoint.md} {
    padding-bottom: ${({ theme }) => theme.gap.sm};
    width: 810px;
  }
`;

export const StyledSelectionCards = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-block: 30px;

  ${breakpoint.md} {
    display: grid;
    max-width: ${({ theme }) => theme.wrapper};
    grid-template-columns: repeat(4, 1fr);
    margin-block: 0;
  }
`;

export const StyledSelectionCardsLoaded = styled(StyledSelectionCards)`
  margin-top: -30px;
`;

export const StyledSelectionMobile = styled.div`
  display: flex;
  max-width: ${({ theme }) => theme.wrapper};
  border-top: 1px solid ${({ theme }) => theme.colors.gray10};
`;

export const StyledCardDescription = styled(Typography)`
  margin-bottom: 0;
  opacity: 0;
  height: 0;
  transform: translateY(20px);
  transition: opacity 0.3s ease, transform 0.3s ease, height 0.3s ease, margin-bottom 0.3s ease; // Dodaj animację
  overflow: hidden;
  pointer-events: none;
`;

export const StyledSelectionCard = styled.div<StyledSelectionCardProps>`
  display: flex;
  flex-direction: row;
  justify-content: start;
  height: 36px;
  width: 328px;
  padding-inline: ${({ theme }) => theme.gap.sm};
  border-right: 1px solid ${({ theme }) => theme.colors.gray10};
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray10};
  cursor: pointer;
  ${({ index }) =>
    index < 4 &&
    css`
      border-top: 1px solid ${({ theme }) => theme.colors.gray10};
    `}

  ${({ index }) =>
    index % 4 === 0 &&
    css`
      border-left: 1px solid ${({ theme }) => theme.colors.gray10};
    `}

 
  ${({ index, total }) => {
    const remainder = total % 4;
    const isLastRow = index >= total - remainder;
    if (isLastRow && index !== total - 1) {
      return css`
        border-bottom: 1px solid ${({ theme }) => theme.colors.gray10};
      `;
    }
    return '';
  }}
 
  
  ${({ index, total }) => {
    const remainder = total % 4;
    if (index >= total - remainder - 4 && index < total - remainder) {
      return css`
        border-bottom: 1px solid ${({ theme }) => theme.colors.gray10};
      `;
    }
    return '';
  }}

  ${breakpoint.md} {
    flex-direction: column;
    justify-content: flex-end;
    height: 272px;
    width: 277.5px;
    padding-left: 24px;
    padding-bottom: 24px;

    &:hover {
      ${StyledCardDescription} {
        opacity: 1;
        height: auto;
        margin-bottom: 24px;
        transform: translateY(0);
        pointer-events: auto;
        margin-top: -12px;
      }
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.grayscaleGray0};
    border-color: ${({ theme }) => theme.colors.grayscaleGray0};

    .card-with-icon__button {
      span {
        display: block;
        animation: move-text-cart 0.2s linear forwards;
      }

      svg {
        animation: move-svg 0.2s linear forwards;
      }
    }

    .card-with-icon__button span {
      ${breakpoint.md} {
        display: block;
      }
    }
  }

  @keyframes move-svg {
    from {
      opacity: 0;
      transform: translateX(-437%);
    }
    to {
      opacity: 1;
      transform: translateX(0%);
    }
  }

  @keyframes move-text-cart {
    from {
      opacity: 0;
      transform: translateX(-100%);
    }
    to {
      opacity: 1;
      transform: translateX(0%);
    }
  }
`;

export const StyledCardText = styled(Typography)`
  margin-top: 34px;
  margin-bottom: 24px;
  margin-left: 27px;
  width: 196px;
  flex-grow: 1;

  ${breakpoint.md} {
    width: 230px;
    flex-grow: unset;
    margin-top: 0%;
    margin-bottom: 24px;
    margin-left: 0;
  }
`;

export const StyledCardIcon = styled(Icon)`
  margin-top: 17px;
  margin-bottom: 17px;

  ${breakpoint.md} {
    margin-top: 0;
    margin-bottom: 18px;
  }
`;

export const StyledCardButton = styled(Button)`
  height: 20px;
  grid-area: button;
  margin-top: 34px;

  ${breakpoint.md} {
    margin-top: 0px;
  }

  span {
    text-decoration: underline;
    display: none;
    ${breakpoint.md} {
      display: none;
      text-decoration: none;
    }
  }
`;

export const StyledCardTeamButton = styled.button`
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.complementary2};
  margin-bottom: 48px;
`;

export const StyledWrapperFullWith = styled.div`
  width: 100%;
`;
